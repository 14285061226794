import './ForClient.css';
import React from 'react';
import { Tabs, Tab, TabPanel, TabList } from 'react-tabs'
import Documents from './Documents';
import FirstSteps from './FirstSteps'

import 'react-tabs/style/react-tabs.css';

function ForClient() {
  return (
    <Tabs>
      <TabList>
        <Tab>Potrzebne dokumenty</Tab>
        <Tab>Pierwsze kroki</Tab>
      </TabList>

      <TabPanel>
        <Documents />
      </TabPanel>
      <TabPanel>
        <FirstSteps />
      </TabPanel>
    </Tabs>
  );
}

export default ForClient;
