import React from 'react';
import { TbLicense } from "react-icons/tb";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

function Okresowe() {
    return (
        <div className="categoryPage">
            <div className="row justify-content-center">
                <div className="col col-12 col-sm-6 col-lg-5">
                    <div className="categoryIconDesc"><TbLicense/></div>
                </div>
                <div className="col col-12 col-sm-6 col-lg-5">
                    <div className="catTitle">Szkolenie okresowe</div>
                    <div className="descriptionContainer">
                        Szkolenie teoretyczne organizowane w formie cyklu zajęć prowadzonych jako wykłady, lub jako ćwiczenia pod nadzorem trenera przy użyciu komputera i specjalistycznego oprogramowania wspomagającego proces szkolenia - BEZ EGZAMINU.
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mt-5">
                <div className="col col-12 col-sm-6 col-lg-10">
                    Zgodnie z obowiązującymi przepisami do kursu mogą przystąpić kandydaci, którzy posiadają <b>profil
                    kierowcy zawodowego</b> wydany na podstawie orzeczenia lekarskiego i psychologicznego o braku
                    przeciwwskazań do pracy na stanowisku kierowcy zgodnie z art. 39j i 39k Ustawy o transporcie
                    drogowym. Profil kierowcy zawodowego pobrać można w Wydziale Komunikacji właściwym dla
                    miejsca zamieszkania kandydata na kierowcę zawodowego.
                </div>
            </div>
            <div className="d-flex align-items-end flex-column">
                <div className="right">
                    <Link to="/Offer"><Button variant="danger">Powrót</Button></Link>
                </div>
            </div>
        </div>
    )
}

export default Okresowe;
