import React from 'react';
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Amimg from "../../../../images/am.jpg";

function AM() {
    return (
        <div className="categoryPage">
            <div className="row justify-content-center ">
                <div className="col col-12 col-sm-6 col-lg-5">
                    <img src={Amimg} className="imgCategory" alt="AM"/>
                </div>
                <div className="col col-12 col-sm-6 col-lg-5">
                    <div className="catTitle">Kurs prawa jazdy kategorii AM</div>
                    <div className="descriptionContainer">
                        Kategoria AM stwierdza posiadanie uprawnień do kierowania:<br/><br/>
                        <ul>
                            <li>motorowerem,</li>
                            <li>czterokołowcem lekkim (czterokołowiec, którego masa własna nie przekracza 350 kg i
                                konstrukcja ogranicza prędkość jazdy do 45 km/h);
                            </li>
                        </ul>
                        <div className="red"> Kurs możesz rozpocząć 3 miesiące przed ukończeniem 14 lat.</div>
                    </div>
                </div>
            </div>
            <div className="brakeLine"/>
            <div className="row justify-content-center mt-4">
                <div className="col col-12 col-sm-6 col-lg-10">
                    Strój motocyklisty zdającego egzamin państwowy w zakresie kat. AM, A1, A2, A, został usankcjonowany
                    przez Dz. u z dnia 5 września 2012 roku poz. 995 z późn. zm. w sprawie egzaminowania osób
                    ubiegających się o uprawnienia do kierowania pojazdami, szkolenia, egzaminowania i uzyskiwania
                    uprawnień przez egzaminatorów oraz wzorów dokumentów stosowanych w tych sprawach.
                    Część stroju motocyklisty w czasie egzaminu państwowego zapewnia WORD . Do nich zaliczamy:<br/><br/>
                    <b>
                        <ul>
                            <li>kamizelkę,</li>
                            <li>kask ochronny,</li>
                            <li>ochraniacze na kolana i łokcie</li>
                        </ul>
                    </b>

                    Inne elementy związane ze strojem motocyklisty zapewnia osoba egzaminowana. Należą do
                    nich:<br/><br/>

                    <ul>
                        <li><b>obuwie</b>, należy posiadać wiązane obuwie na płaskiej podeszwie zabrania się jazdy na
                            motocyklu
                            w
                            klapkach, sandałach, obuwiu wsuwanym bez jakiegokolwiek zabezpieczenia;
                        </li>
                        <li><b>spodnie z długimi nogawkami</b>, wskazane jest stosowanie spodni ze skóry lub innego
                            materiału
                            odpornego
                            na czynniki zewnętrzne, występujące bądź mogące wystąpić podczas kierowania motocyklem;
                        </li>
                        <li><b>kurtkę z długimi rękawami zapinaną na suwak</b>, guziki lub zatrzaski, wymagana jest
                            kurka z
                            właściwego
                            materiału, ochraniająca motocyklistę a nie tylko spełniająca pewne kryterium prawne. Kurtki
                            te
                            posiadają także elementy ochronne kręgosłupa, o których zapomniano w wymogach formalnych.
                            Ważna też
                            jest barwa kurki oraz jej elementy odblaskowe;
                        </li>
                        <li><b>rękawice zakrywające całe dłonie</b>, skuteczną ochronę dłoni zapewnią jedynie rękawice
                            przeznaczone do
                            tego celu.
                        </li>
                    </ul>
                    <div className="warning">STRÓJ TEN OBOWIĄZUJE RÓWNIEŻ NA JAZDACH PODCZAS SZKOLENIA!!!</div>
                </div>
            </div>
            <div className="d-flex align-items-end flex-column">
                <div className="right">
                    <Link to="/Offer"><Button variant="danger">Powrót</Button></Link>
                </div>
            </div>
        </div>
    )
}

export default AM;
