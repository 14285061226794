import React from 'react';

function FirstSteps() {
    return (
        <div className='steps'>
            Przyjdź do <b>OSK FENIX w Tczewie</b>, a pomożemy ci wypełnić i skompletować dokumenty wymagane przy
            założeniu Profilu Kandydata na Kierowcę (PKK)<br/><br/>

            <b>Wymagane dokumenty:</b><br/><br/>
            <ul>
                <li>wniosek o wydanie prawa jazdy (prawidłowo wypełniony zgodnie ze wzorem)</li>
                <li>orzeczenie lekarskie o braku przeciwwskazań zdrowotnych do kierowania pojazdami</li>
                <li>jedna fotografia o wym. 3,5 x 4,5 cm w pozycji na wprost</li>
                <li>dowód osobisty (dowód osobisty, tymczasowy dowód osobisty, legitymacja szkolna lub paszport
                    kandydata (wraz z urzędowym potwierdzeniem zameldowania) - do wglądu
                </li>
                <li>kserokopia karty pobytu oraz karta pobytu do wglądu - dla cudzoziemca</li>
            </ul>

            <b>DLA OSÓB UBIEGAJĄCYCH SIĘ O WYDANIE PRAWA JAZDY Z KOLEJNĄ KATEGORIĄ</b><br/><br/>
            <ul>
                <li>zaświadczenie o ukończeniu kursu i uzyskaniu pozytywnego wyniku egzaminu państwowego albo kserokopię
                    prawa jazdy
                    <ul>
                        <li>w zakresie kategorii B jeżeli ubiega się o prawo jazdy odpowiednio kategorii B+E</li>
                        <li>w zakresie kategorii A2 jeżeli ubiega się o prawo jazdy odpowiednio kategorii A oraz posiada
                            zaświadczenie potwierdzające odbycie tylko zajęć praktycznych
                        </li>
                    </ul>
                </li>
            </ul>
            <b>Po zdanym egzaminie:</b><br/>
            Wejdź na stronę <a href="https://www.kierowca.pwpw.pl/">www.kierowca.pwpw.pl</a> i sprawdź czy Twoje prawo
            jazdy jest już do odbioru w Urzędzie.<br/><br/>

            <b>OBCOKRAJOWIEC MOŻE OTRZYMAĆ PRAWO JAZDY W POLSCE JEŻELI:</b><br/><br/>

            <ul>
                <li>Uzyska orzeczenie lekarskie o braku przeciwwskazań zdrowotnych do kierowania pojazdem</li>
                <li> Odbędzie wymagane dla danej kategorii szkolenie;</li>
                <li>Zda z wynikiem pozytywnym egzamin państwowy wymagany dla danej kategorii;</li>
                <li>przebywa na terytorium Rzeczypospolitej Polskiej przez co najmniej 185 dni w każdym roku
                    kalendarzowym ze względu na swoje więzi osobiste lub zawodowe albo przedstawi zaświadczenie, że
                    studiuje od co najmniej sześciu miesięcy.
                </li>
            </ul>
            <b>WYMAGANE DOKUMENTY:</b><br/><br/>
            <ol>
                <li>Dowód osobisty, tymczasowy dowód osobisty, paszport, a dla cudzoziemców paszport lub kartę stałego
                    pobytu - do wglądu;
                </li>
                <li>Podanie;</li>
                <li>Dowód uiszczenia opłaty za egzamin(potwierdzenie przyjęcia opłaty przez kasę WORD potwierdzenie
                    przelewu na rachunek bankowy);
                </li>
                <li>Jedną fotografię bez nakrycia głowy, nie podklejoną o wymiarach 3, 5 x 4, 5 cm.</li>
                <li>Orzeczenie lekarza o braku przeciwwskazań zdrowotnych do kierowania pojazdami</li>
                <li>Kserokopię posiadanego prawa jazdy kategorii B, jeżeli ubiega się o prawo jazdy kategorii B + E</li>
            </ol>

            Złożone przy wnioskowaniu o prawo jazdy dokumenty zostaną przekazane przez WORD do Wydziału Komunikacji,
            właściwego dla miejsca zameldowania wnioskującego.<br/><br/>

            <b>DLA OSÓB UBIEGAJĄCYCH SIĘ O WYMIANĘ PRAWA JAZDY WYDANEGO ZA GRANICĄ</b><br/>
            (dokumenty należy składać w WORD)<br/><br/>

            Osoby ubiegające się o wymianę prawa jazdy wydanego za granicą powinny poddać się sprawdzeniu kwalifikacji w
            zakresie egzaminu teoretycznego.<br/><br/>

            Wykaz państw, których krajowe prawa jazdy są ważne na terytorium RP i mogą być wymienione w Urzędach bez
            konieczności zdawania egzaminu teoretyczne(Dz.U.Nr 286 poz. 2884 z 28 grudnia 2004, zał. 4)<br/><br/>

            <ul>
                <li>wniosek na specjalnym druku(do pobrania w Biurze Obsługi Egzaminów)</li>
                <li>potwierdzenie uiszczenia opłaty za egzamin</li>
                <li>jedna fotografia o wym. 3, 5 x 4, 5 cm w pozycji na wprost</li>
                <li>kserokopię posiadanego prawa jazdy z uwierzytelnionym tłumaczeniem na język polski(sporządzonym lub
                    poświadczonym przez tłumacza przysięgłego lub właściwie umocowanego urzędnika przedstawicielstw
                    dyplomatycznych, urzędów konsularnych i misji specjalnych)
                </li>
                <li>dowód osobisty, tymczasowy dowód osobisty, lub paszport kandydata(wraz z urzędowym potwierdzeniem
                    zameldowania) - do wglądu
                </li>
                <li>kserokopia karty pobytu oraz karta pobytu do wglądu - dla cudzoziemca</li>
                <li>PEŁNOMOCNICTWO, jeżeli wyznaczony został pełnomocnik do dostarczenia dokumentów</li>
            </ul>
        </div>
    );
}

export default FirstSteps;
