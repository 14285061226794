import React from 'react';
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Cimg from "../../../../images/c.jpg";

function C() {
    return (
        <div className="categoryPage">
            <div className="row justify-content-center ">
                <div className="col col-12 col-sm-6 col-lg-5">
                    <img src={Cimg} className="imgCategory" alt="C"/>
                </div>
                <div className="col col-12 col-sm-6 col-lg-5">
                    <div className="catTitle">Kurs prawa jazdy kategorii C</div>
                    <div className="descriptionContainer">
                        Kategoria C stwierdza posiadanie uprawnień do kierowania:<br/><br/>
                        <ul>
                            <li>pojazdem samochodowym o dopuszczalnej masie całkowitej przekraczającej 3,5 t, z wyjątkiem autobusu,</li>
                            <li>pojazdem samochodowym o dopuszczalnej masie całkowitej przekraczającej 3,5 t oraz przyczepy lekkiej</li>
                        </ul>
                        <div className="red">Kurs możesz rozpocząć 3 miesiące przed ukończeniem 21 lat.</div>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-end flex-column">
                <div className="right">
                    <Link to="/Offer"><Button variant="danger">Powrót</Button></Link>
                </div>
            </div>
        </div>
    )
}

export default C;
