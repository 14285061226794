import './Logo.css';
import logoImage from "../../images/logo.png"
import React, { useMemo, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import parse from 'html-react-parser';


function Logo() {
  const [data, setData] = useState({ "slider": [{ text: "" }] });

  useMemo(() => {
    fetch('./data.json'
      , {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Cache-Control': 'no-cache'
        }
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson)
      });
  }, [])

  var settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots:false
  };

  return (
    <div className="logo">
      <img src={logoImage} alt="Logo" />
      <div className='slidebar'>
      <Slider {...settings}>
      {data.slider.map(item => {
        return parse("<div>"+item.text+"</div>")
      })}
      </Slider>
      </div>
    </div>
  );
}

export default Logo;
