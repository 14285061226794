import './Fleet.css';
import React, { useMemo, useState } from 'react';
import { Container, Row } from 'react-bootstrap';

function Fleet() {

  const [data, setData] = useState({ flota: [{ title: "", img: "" }] });

  useMemo(() => {
    fetch('./data.json'
      , {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Cache-Control': 'no-cache'
        }
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson)
      });
  }, [])

  return (
    <Container className="container-sm p-0 m-auto">
      {
        data["flota"].map(kategoria => {
          return (

            <Row className="align-items-center justify-content-center p-2 text-center" xs="2">
              <h1>{kategoria.title}</h1>
              <img src={"./../images/" + kategoria.img} className="imgKat" alt={kategoria.img}/>
              <hr className='line'/>
            </Row>
          )
        }
        )
      }
    </Container>
  );
}

export default Fleet;
