import React from 'react';
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import CEimg from "../../../../images/ce.jpg";

function CE() {
    return (
        <div className="categoryPage">
            <div className="row justify-content-center ">
                <div className="col col-12 col-sm-6 col-lg-5">
                    <img src={CEimg} className="imgCategory" alt="CE"/>
                </div>
                <div className="col col-12 col-sm-6 col-lg-5">
                    <div className="catTitle">Kurs prawa jazdy kategorii C+E</div>
                    <div className="descriptionContainer">
                        Kategoria C+E  stwierdza posiadanie uprawnień do kierowania:<br/><br/>
                        <ul>
                            <li>pojazdami określonymi odpowiednio w prawie jazdy kategorii C, łącznie z przyczepą (przyczepami)</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-end flex-column">
                <div className="right">
                    <Link to="/Offer"><Button variant="danger">Powrót</Button></Link>
                </div>
            </div>
        </div>
    )
}

export default CE;
