import './Contact.css';
import React from 'react';


function Contact() {

  const ggsrc = "http://status.gadu-gadu.pl/users/status.asp?id=53293370&styl=1"

  return (
    <div className='contact'>
      <b>OSK FENIX</b><br />
      Al. Zwycięstwa 12/2<br />
      83-110 Tczew<br /><br />
      Biuro czynne:<br />
      Pn - Pt<br />
      11:00-17:00<br /><br />

      Gadu-gadu: <img src={ggsrc} alt="gg"/> 53293370<br /><br /><br />


      <h2>Tel. 570 119 411</h2><br />
      Numer konta bankowego: 69 1320 1537 2097 3555 2000 0002<br />
      W tytule wpłaty (proszę podać imię, nazwisko i kategorię prawa jazdy )<br /><br />

      NIP 5781617406<br />
      REGON 360358330
    </div>
  );
}

export default Contact;
