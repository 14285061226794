import React from 'react';
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import BEimg from "../../../../images/be.jpg";

function BE() {
    return (
        <div className="categoryPage">
            <div className="row justify-content-center ">
                <div className="col col-12 col-sm-6 col-lg-5">
                    <img src={BEimg} className="imgCategory" alt="BE"/>
                </div>
                <div className="col col-12 col-sm-6 col-lg-5">
                    <div className="catTitle">Kurs prawa jazdy kategorii B+E</div>
                    <div className="descriptionContainer">
                        Kategoria B + E stwierdza posiadanie uprawnień do kierowania:<br/><br/>
                        <ul>
                            <li>pojazdami określonymi odpowiednio w prawie jazdy kategorii B, łącznie z przyczepą, przy czym dopuszczalna masa całkowita ciągniętej przyczepy nie może przekraczać 3,5 t.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-end flex-column">
                <div className="right">
                    <Link to="/Offer"><Button variant="danger">Powrót</Button></Link>
                </div>
            </div>
        </div>
    )
}

export default BE;
