import './Category.css';
import React from 'react';
import { useParams } from 'react-router-dom';
import AM from "./Categories/AM";
import A from "./Categories/A";
import B from "./Categories/B";
import BE from "./Categories/BE";
import C from "./Categories/C";
import CE from "./Categories/CE";
import KW from "./Categories/KW";
import KWP from "./Categories/KWP";
import KWU from "./Categories/KWU";
import KWUP from "./Categories/KWUP";
import Okresowe from "./Categories/Okresowe";

function Category() {
    let {category} = useParams();

    if (category === "AM") {
        return (
            <div className="flex-container">
                <AM/>
            </div>
        );
    } else if (category === "A") {
        return (
            <div className="flex-container">
                <A/>
            </div>
        );
    } else if (category === "B") {
        return (
            <div className="flex-container">
                <B/>
            </div>
        );
    } else if (category === "BE") {
        return (
            <div className="flex-container">
                <BE/>
            </div>
        );
    } else if (category === "C") {
        return (
            <div className="flex-container">
                <C/>
            </div>
        );
    } else if (category === "CE") {
        return (
            <div className="flex-container">
                <CE/>
            </div>
        );
    } else if (category === "KW") {
        return (
            <div className="flex-container">
                <KW/>
            </div>
        );
    } else if (category === "KWP") {
        return (
            <div className="flex-container">
                <KWP/>
            </div>
        );
    } else if (category === "KWU") {
        return (
            <div className="flex-container">
                <KWU/>
            </div>
        );
    } else if (category === "KWUP") {
        return (
            <div className="flex-container">
                <KWUP/>
            </div>
        );
    } else if (category === "Okresowe") {
        return (
            <div className="flex-container">
                <Okresowe/>
            </div>
        );
    } else {
        return (
            <div className="flex-container">
                Błąd. Nie ma takiej kategorii.
            </div>
        );
    }
}

export default Category;
