// noinspection HttpUrlsUsage

import React from 'react';
import { Table } from 'react-bootstrap';


function Documents() {
    return (
        <div>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th><h2>Nazwa kursu</h2></th>
                    <th><h2>Wymagane dokumenty</h2></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Kategoria A, A2, B, B+E</td>
                    <td>
                        <ul>
                            <li>dokument tożsamości (dowód osobisty, paszport lub karta pobytu)</li>
                            <li>1 kolorowe zdjęcie (3,5 cm x 4,5 cm z wizerunkiem od przodu, jak do prawa jazdy)</li>
                            <li>badania lekarskie (namiary do lekarza można uzyskać w biurze)</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>Kategoria AM, A1</td>
                    <td>
                        <ul>
                            <li>dokument tożsamości (dowód osobisty, paszport lub karta pobytu)</li>
                            <li>1 kolorowe zdjęcie (3,5 cm x 4,5 cm z wizerunkiem od przodu, jak do prawa jazdy)</li>
                            <li>badania lekarskie (namiary do lekarza można uzyskać w biurze)</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>Kategoria C, C+E</td>
                    <td>
                        <ul>
                            <li>dokument tożsamości (dowód osobisty, paszport lub karta pobytu)
                            </li>
                            <li>prawo jazdy</li>
                            <li>1 kolorowe zdjęcie (3,5 cm x 4,5 cm z wizerunkiem od przodu, jak do prawa jazdy)</li>
                            <li>badania lekarskie (namiary do lekarza można uzyskać w biurze)</li>
                            <li>orzeczenie psychologiczne</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <center><b><h3>DO ZAPISU NA POZOSTAŁE KURSY WYSTARCZĄ NASTĘPUJĄCE DOKUMENTY</h3></b></center>
                    </td>
                </tr>
                <tr>
                    <td>Kwalifikacja wstępna</td>
                    <td>
                        <ul>
                            <li>dowód osobisty (do wglądu)</li>
                            <li>Profil Kierowcy Zawodowego (PKZ) <span className="red">*</span></li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>Szkolenie okresowe</td>
                    <td>
                        <ul>
                            <li>dowód osobisty (do wglądu)</li>
                            <li>Profil Kierowcy Zawodowego (PKZ) <span className="red">*</span></li>
                        </ul>
                    </td>
                </tr>
                </tbody>
            </Table>
            <p className="red">* Numer PKZ można uzyskać w wydziale komunikacji zgodnym z miejscem
                zameldowania.<br/><br/>
                <b>Wymagane dokumenty do uzyskania wniosku:</b><br/>
                <ul>
                    <li>dokument tożsamości (dowód osobisty, paszport lub karta pobytu)</li>
                    <li>prawo jazdy</li>
                    <li>badania lekarskie stwierdzające brak przeciwwskazań zdrowotnych i psychologicznych do
                        wykonywania pracy na stanowisku kierowcy
                    </li>
                    <li>badania psychologiczne stwierdzające brak przeciwwskazań zdrowotnych i psychologicznych do
                        wykonywania pracy na stanowisku kierowcy
                    </li>
                    <li>1 kolorowe zdjęcie (3,5 cm x 4,5 cm z wizerunkiem od przodu, jak do prawa jazdy)</li>
                    <li>wypełniony wniosek</li>
                </ul>
            </p>
        </div>
    );
}

export default Documents;
