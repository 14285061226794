import React from 'react';
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import A1img from "../../../../images/a2.jpg";
import A2img from "../../../../images/a1.jpg";
import Aimg from "../../../../images/a.jpg";

function A() {
    return (
        <div className="categoryPage">
            <div className="row justify-content-center ">
                <div className="col col-12 col-sm-6 col-lg-5">
                    <img src={A1img} className="imgCategory" alt="A1"/>
                </div>
                <div className="col col-12 col-sm-6 col-lg-5">
                    <div className="catTitle">Kurs prawa jazdy kategorii A1 od 16 lat</div>
                    <div className="descriptionContainer">
                        Kategoria A1 stwierdza posiadanie uprawnień do kierowania:<br/><br/>
                        <ul>
                            <li>motocyklem o pojemności skokowej silnika nieprzekraczającej 125 cm3, mocy
                                nieprzekraczającej 11kW i stosunku mocy do masy własnej nieprzekraczającym 0,1 kW/kg,
                            </li>
                            <li>motocyklem trójkołowym o mocy nieprzekraczającej 15 KW</li>
                            <li>pojazdami określonymi dla prawa jazdy kategorii AM</li>
                        </ul>
                        <div className="red"> Kurs możesz rozpocząć 3 miesiące przed ukończeniem 16 lat.</div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mt-4">
                <div className="col col-12 col-sm-6 col-lg-10">
                    Osoba posiadająca kategorię B przez okres 3 lat, jest uprawniona do kierowania motocyklem o
                    pojemności skokowej silnika nieprzekraczającej 125 cm3, mocy nieprzekraczającej 11kW i stosunku mocy
                    do masy własnej nieprzekraczającym 0,1 kW/kg,
                </div>
            </div>
            <div className="brakeLine"/>
            <div className="row justify-content-center ">
                <div className="col col-12 col-sm-6 col-lg-5">
                    <img src={A2img} className="imgCategory" alt="A2"/>
                </div>
                <div className="col col-12 col-sm-6 col-lg-5">
                    <div className="catTitle">Kurs prawa jazdy kategorii A2 od 18 lat</div>
                    <div className="descriptionContainer">
                        Kategoria A2 stwierdza posiadanie uprawnień do kierowania:<br/><br/>
                        <ul>
                            <li>motocyklem o mocy nie przekraczającej 35 KW i stosunku mocy do masy własnej
                                nieprzekraczającym 0,2 kW/kg,
                            </li>
                            <li>motocyklem trójkołowym o mocy nieprzekraczającej 15 KW</li>
                            <li>pojazdami określonymi dla prawa jazdy kategorii A1</li>
                        </ul>
                        <div className="red"> Kurs możesz rozpocząć 3 miesiące przed ukończeniem 18 lat.</div>
                    </div>
                </div>
            </div>
            <div className="brakeLine"/>
            <div className="row justify-content-center ">
                <div className="col col-12 col-sm-6 col-lg-5">
                    <img src={Aimg} className="imgCategory" alt="A"/>
                </div>
                <div className="col col-12 col-sm-6 col-lg-5">
                    <div className="catTitle">Kurs prawa jazdy kategorii A od 24 lat</div>
                    <div className="descriptionContainer">
                        Kategoria A stwierdza posiadanie uprawnień do kierowania:<br/><br/>
                        <ul>
                            <li>motocyklem</li>
                            <li>pojazdami określonymi dla prawa jazdy kategorii A2</li>
                        </ul>
                        <div className="red"> Kurs możesz rozpocząć 3 miesiące przed ukończeniem 24 lat.</div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mt-4">
                <div className="col col-12 col-sm-6 col-lg-10">
                    Osoba posiadająca kategorie A2 przez okres 2 lat, może starać sie o uzyskanie uprawnień do
                    kierowania pojazdami określonymi w prawo jazdy kategorii A w wieku 20 lat.
                </div>
            </div>
            <div className="brakeLine"/>
            <div className="row justify-content-center mt-4">
                <div className="col col-12 col-sm-6 col-lg-10">
                    Strój motocyklisty zdającego egzamin państwowy w zakresie kat. AM, A1, A2, A, został usankcjonowany
                    przez Dz. u z dnia 5 września 2012 roku poz. 995 z późn. zm. w sprawie egzaminowania osób
                    ubiegających się o uprawnienia do kierowania pojazdami, szkolenia, egzaminowania i uzyskiwania
                    uprawnień przez egzaminatorów oraz wzorów dokumentów stosowanych w tych sprawach.
                    Część stroju motocyklisty w czasie egzaminu państwowego zapewnia WORD . Do nich zaliczamy:<br/><br/>
                    <b>
                        <ul>
                            <li>kamizelkę,</li>
                            <li>kask ochronny,</li>
                            <li>ochraniacze na kolana i łokcie</li>
                        </ul>
                    </b>

                    Inne elementy związane ze strojem motocyklisty zapewnia osoba egzaminowana. Należą do
                    nich:<br/><br/>

                    <ul>
                        <li><b>obuwie</b>, należy posiadać wiązane obuwie na płaskiej podeszwie zabrania się jazdy na
                            motocyklu
                            w
                            klapkach, sandałach, obuwiu wsuwanym bez jakiegokolwiek zabezpieczenia;
                        </li>
                        <li><b>spodnie z długimi nogawkami</b>, wskazane jest stosowanie spodni ze skóry lub innego
                            materiału
                            odpornego
                            na czynniki zewnętrzne, występujące bądź mogące wystąpić podczas kierowania motocyklem;
                        </li>
                        <li><b>kurtkę z długimi rękawami zapinaną na suwak</b>, guziki lub zatrzaski, wymagana jest
                            kurka z
                            właściwego
                            materiału, ochraniająca motocyklistę a nie tylko spełniająca pewne kryterium prawne. Kurtki
                            te
                            posiadają także elementy ochronne kręgosłupa, o których zapomniano w wymogach formalnych.
                            Ważna też
                            jest barwa kurki oraz jej elementy odblaskowe;
                        </li>
                        <li><b>rękawice zakrywające całe dłonie</b>, skuteczną ochronę dłoni zapewnią jedynie rękawice
                            przeznaczone do
                            tego celu.
                        </li>
                    </ul>
                    <div className="warning">STRÓJ TEN OBOWIĄZUJE RÓWNIEŻ NA JAZDACH PODCZAS SZKOLENIA!!!</div>
                </div>
            </div>
            <div className="d-flex align-items-end flex-column">
                <div className="right">
                    <Link to="/Offer"><Button variant="danger">Powrót</Button></Link>
                </div>
            </div>
        </div>
    )
}

export default A;
