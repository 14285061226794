import React from 'react';
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Bimg from "../../../../images/b.jpg";

function B() {
    return (
        <div className="categoryPage">
            <div className="row justify-content-center ">
                <div className="col col-12 col-sm-6 col-lg-5">
                    <img src={Bimg} className="imgCategory" alt="B"/>
                </div>
                <div className="col col-12 col-sm-6 col-lg-5">
                    <div className="catTitle">Kurs prawa jazdy kategorii B</div>
                    <div className="descriptionContainer">
                        Kategoria B stwierdza posiadanie uprawnień do kierowania:<br/><br/>
                        <ul>
                            <li>samochodem osobowym o dopuszczalnej masie całkowitej nieprzekraczającej 3,5 t</li>
                            <li>samochodem osobowym z przyczepą o dopuszczalnej masie całkowitej nieprzekraczającej masy własnej pojazdu ciągnącego, o ile łączna dopuszczalna masa całkowita zespołu pojazdów nie przekracza 3,5 t
                            </li>
                        </ul>
                        <div className="red"> Kurs możesz rozpocząć 3 miesiące przed ukończeniem 18 lat.</div>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-end flex-column">
                <div className="right">
                    <Link to="/Offer"><Button variant="danger">Powrót</Button></Link>
                </div>
            </div>
        </div>
    )
}

export default B;
