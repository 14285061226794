import React from 'react';
import { TbLicense } from "react-icons/tb";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

function KW() {
    return (
        <div className="categoryPage">
            <div className="row justify-content-center ">
                <div className="col col-12 col-sm-6 col-lg-5">
                    <div className="categoryIconDesc"><TbLicense/></div>
                </div>
                <div className="col col-12 col-sm-6 col-lg-5">
                    <div className="catTitle">Kwalifikacja wstępna</div>
                    <div className="descriptionContainer">
                        <b>Dotyczy Osób które uzyskały uprawnienia po 10.09.2008 roku w przypadku kategorii D<br/><br/>

                            Dotyczy Osób które uzyskały uprawnienia po 10.09.2009 roku w przypadku kategorii
                            C</b><br/><br/>
                        Rodzaj świadectwa kwalifikacji wstępnej uzależniony jest od wieku:<br/><br/>

                        <ul>
                            <li>C,CE - mając ukończone 18 lat</li>
                            <li>D - mając ukończone 21 lat</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mt-5">
                <div className="col col-12 col-sm-6 col-lg-10">
                    Zgodnie z obowiązującymi przepisami do kursu mogą przystąpić kandydaci, którzy posiadają <b>profil
                    kierowcy zawodowego</b> wydany na podstawie orzeczenia lekarskiego i psychologicznego o braku
                    przeciwwskazań do pracy na stanowisku kierowcy zgodnie z art. 39j i 39k Ustawy o transporcie
                    drogowym. Profil kierowcy zawodowego pobrać można w Wydziale Komunikacji właściwym dla
                    miejsca zamieszkania kandydata na kierowcę zawodowego.
                </div>
            </div>
            <div className="d-flex align-items-end flex-column">
                <div className="right">
                    <Link to="/Offer"><Button variant="danger">Powrót</Button></Link>
                </div>
            </div>
        </div>
    )
}

export default KW;
