import './Home.css';
import React, { useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import bon from "../../images/bon.jpg"
import ReactHtmlParser from 'react-html-parser';


function Home() {

  const [data, setData] = useState({news: [{title: "", body: "", img: "", imgWidth: ""}]});
  const ggsrc = "http://status.gadu-gadu.pl/users/status.asp?id=53293370&styl=1"

  useMemo(() => {
    fetch('./data.json'
      , {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Cache-Control': 'no-cache'
        }
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson)
      });
  }, [])

  return (
    <Container className="container-sm p-0 m-auto">
      <Row className="justify-content-xs-center p-2">
        <Col className='box right'>
          <Row><div className='title'>Bon podarunkowy</div></Row>
          <Row className='bon'>
            Chcesz sprezentować komuś kurs z naszej oferty na urodziny lub inną okazję?<br /><br />

            Bony do kupienia w biurze za cenę wybranego kursu.<br /><br />
            <img src={bon} alt="Zdjęcie bonu" className='bonBorder' />
          </Row>
        </Col>
        <Col sm="9" className='box'>
          <Row><div className='title'>Aktualności</div></Row>
          {data["news"].map(news => {
            return (
              <Row className='rowNews'>
                <div className='titleNews'>{ReactHtmlParser(news.title)}</div>
                <div className='bodyNews'>{ReactHtmlParser(news.body)}</div>
                <center><img src={"./../images/" + news.img} className="imgNews" alt="Zdjęcie nowych motorów" width={news.imgWidth} /></center>
                <hr />
              </Row>
            )
          })
          }
        </Col>
      </Row>
      <Row className='box justify-content-center'>
        <div className='title'>Kontakt</div>
        <center>OSK FENIX<br />
        Al. Zwycięstwa 12/2<br />
        83-110 Tczew<br />
        Biuro czynne:<br />
        Pn - Pt<br />
        11:00-17:00<br /><br />

        Gadu-gadu: <img src={ggsrc} alt="gg"/> 53293370<br /><br />


        <b>Tel. 570 119 411</b><br />
        (Prosimy dzwonić w czasie pracy biura)<br /><br /></center>
      </Row>
    </Container>
  );
}

export default Home;
