import './Offer.css';
import React from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import { MdOutlineMoped } from 'react-icons/md'
import { FaMotorcycle, FaCaravan } from 'react-icons/fa'
import { AiOutlineCar } from 'react-icons/ai'
import { BsTruck } from 'react-icons/bs'
import { GiTruck } from 'react-icons/gi'
import { TbLicense } from 'react-icons/tb'

function Offer() {
    return (
        <div className="flex-container">
            <div className="row justify-content-center">
                <div className="category col col-12 col-sm-6 col-lg-4">
                    <Link to="/Offer/Category/AM">
                        <div className="categoryIcon"><MdOutlineMoped/></div>
                        <br/>
                        <div className="categoryTitle">Kategoria AM</div>
                        <br/>
                        <div className="buttonMore">
                            <Button variant="outline-danger" disabled>CZYTAJ WIĘCEJ</Button>
                        </div>
                    </Link>
                </div>
                <div className="category col col-12 col-sm-6 col-lg-4">
                    <Link to="/Offer/Category/A">
                        <div className="categoryIcon"><FaMotorcycle/></div>
                        <br/>
                        <div className="categoryTitle">Kategoria A1, A2, A</div>
                        <br/>
                        <div className="buttonMore">
                            <Button variant="outline-danger" disabled>CZYTAJ WIĘCEJ</Button>
                        </div>
                    </Link>
                </div>
                <div className="category col col-12 col-sm-6 col-lg-4">
                    <Link to="/Offer/Category/B">
                        <div className="categoryIcon"><AiOutlineCar/></div>
                        <br/>
                        <div className="categoryTitle">Kategoria B</div>
                        <br/>
                        <div className="buttonMore">
                            <Button variant="outline-danger" disabled>CZYTAJ WIĘCEJ</Button>
                        </div>
                    </Link>
                </div>
                <div className="category col col-12 col-sm-6 col-lg-4">
                    <Link to="/Offer/Category/BE">
                        <div className="categoryIcon"><FaCaravan/></div>
                        <br/>
                        <div className="categoryTitle">Kategoria B+E</div>
                        <br/>
                        <div className="buttonMore">
                            <Button variant="outline-danger" disabled>CZYTAJ WIĘCEJ</Button>
                        </div>
                    </Link>
                </div>
                <div className="category col col-12 col-sm-6 col-lg-4">
                    <Link to="/Offer/Category/C">
                        <div className="categoryIcon"><BsTruck/></div>
                        <br/>
                        <div className="categoryTitle">Kategoria C</div>
                        <br/>
                        <div className="buttonMore">
                            <Button variant="outline-danger" disabled>CZYTAJ WIĘCEJ</Button>
                        </div>
                    </Link>
                </div>
                <div className="category col col-12 col-sm-6 col-lg-4">
                    <Link to="/Offer/Category/CE">
                        <div className="categoryIcon"><GiTruck/></div>
                        <br/>
                        <div className="categoryTitle">Kategoria C+E</div>
                        <br/>
                        <div className="buttonMore">
                            <Button variant="outline-danger" disabled>CZYTAJ WIĘCEJ</Button>
                        </div>
                    </Link>
                </div>
                <div className="category col col-12 col-sm-6 col-lg-4">
                    <Link to="/Offer/Category/Okresowe">
                        <div className="categoryIcon"><TbLicense/></div>
                        <br/>
                        <div className="categoryTitle">Szkolenia Okresowe</div>
                        <br/>
                        <div className="buttonMore">
                            <Button variant="outline-danger" disabled>CZYTAJ WIĘCEJ</Button>
                        </div>
                    </Link>
                </div>
                <div className="category col col-12 col-sm-6 col-lg-4">
                    <Link to="/Offer/Category/KWP">
                        <div className="categoryIcon"><TbLicense/></div>
                        <br/>
                        <div className="categoryTitle">Kwalifikacja Wstępna Przyspieszona</div>
                        <br/>
                        <div className="buttonMore">
                            <Button variant="outline-danger" disabled>CZYTAJ WIĘCEJ</Button>
                        </div>
                    </Link>
                </div>
                <div className="category col col-12 col-sm-6 col-lg-4">
                    <Link to="/Offer/Category/KW">
                        <div className="categoryIcon"><TbLicense/></div>
                        <br/>
                        <div className="categoryTitle">Kwalifikacja Wstępna</div>
                        <br/>
                        <div className="buttonMore">
                            <Button variant="outline-danger" disabled>CZYTAJ WIĘCEJ</Button>
                        </div>
                    </Link>
                </div>
                <div className="category col col-12 col-sm-6 col-lg-4">
                    <Link to="/Offer/Category/KWUP">
                        <div className="categoryIcon"><TbLicense/></div>
                        <br/>
                        <div className="categoryTitle">Kwalifikacja Wstępna Uzupełniająca Przyspieszone</div>
                        <br/>
                        <div className="buttonMore">
                            <Button variant="outline-danger" disabled>CZYTAJ WIĘCEJ</Button>
                        </div>
                    </Link>
                </div>
                <div className="category col col-12 col-sm-6 col-lg-4">
                    <Link to="/Offer/Category/KWU">
                        <div className="categoryIcon"><TbLicense/></div>
                        <br/>
                        <div className="categoryTitle">Kwalifikacja Wstępna Uzupełniająca</div>
                        <br/>
                        <div className="buttonMore">
                            <Button variant="outline-danger" disabled>CZYTAJ WIĘCEJ</Button>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Offer;
