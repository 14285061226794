import './Instructors.css';
import React, { useMemo, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';


function Instructors() {

  const [data, setData] = useState({ instruktorzy: [{ imieiNazwisko: "", telefon: "", opis: "" }] });

  useMemo(() => {
    fetch('./data.json'
      , {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Cache-Control': 'no-cache'
        }
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson)
      });
  }, [])

  return (
    <div className='instruktorzy'>
      {data["instruktorzy"].map(instruktor => {
        return (
          <div className='instruktorBox'>
            <div className="imieInstruktora">
              {ReactHtmlParser(instruktor.imieiNazwisko)}
            </div>
            <div className="telInstruktora">
              tel. {ReactHtmlParser(instruktor.telefon)}
            </div>
            <div className="opisInstruktora">
              {ReactHtmlParser(instruktor.opis)}
            </div>
          </div>
        )
      })
      }
    </div>
  );
}

export default Instructors;
