import './App.css';
import Logo from "./Content/Logo/Logo"
import { Link, Route, Routes } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import { useState, useEffect } from 'react';
import CookieConsent from "react-cookie-consent";

import Home from './Content/Home/Home';
import Offer from './Content/Offer/Offer';
import ForClient from './Content/ForClient/ForClient';
import Prices from './Content/Prices/Prices';
import Instructors from './Content/Instructors/Instructors';
import Contact from './Content/Contact/Contact';
import Fleet from './Content/Fleet/Fleet';
import Category from "./Content/Offer/Category/Category";

function App() {

  const [bars, setBars] = useState();
  const [phones, setPhones] = useState("headerBlackLine");
  const [barsClose, setBarsClose] = useState();
  const [menuTransform, setMenuTransform] = useState("headerBlackMenu");

  useEffect(() => {
    if (isMobile) {
      setPhones("")
      setBars("bars")
    }
    else {
      setBars("barsNone")
    }
  }, [])

  function barsClicked() {
    if (isMobile) {
      if (bars === "bars") {
        setMenuTransform("headerBlackMenu translateMenu")
        setBars("barsNone")
        setBarsClose("barsClose")
      } else {
        setMenuTransform("headerBlackMenu ")
        setBars("bars")
        setBarsClose("")
      }
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className={phones}>
        </div>
        <div className={bars} onClick={() => barsClicked()}><div className="barsText">MENU</div></div>
        <Logo />
        <div className={menuTransform}>
        <div className={barsClose} onClick={() => barsClicked()}/>
          <nav>
            <Link to="/Home" onClick={() => barsClicked()}>Strona Główna</Link>
            <Link to="/Offer" onClick={() => barsClicked()}>Oferta</Link>
            <Link to="/ForClient" onClick={() => barsClicked()}>Dla Kursanta</Link>
            <Link to="/Prices" onClick={() => barsClicked()}>Cennik</Link>
            <Link to="/Instructors" onClick={() => barsClicked()}>Instruktorzy</Link>
            <Link to="/Contact" onClick={() => barsClicked()}>Kontakt</Link>
            <Link to="/Fleet" onClick={() => barsClicked()}>Nasza Flota</Link>
            <div className='facebook'><a href="https://www.facebook.com/oskfenix"><i className="fa-brands fa-facebook-square"></i></a></div>
          </nav>
        </div>
      </header>
      <div className='bodyContainer'>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Offer" element={<Offer />} />
          <Route path="/ForClient" element={<ForClient />} />
          <Route path="/Prices" element={<Prices />} />
          <Route path="/Instructors" element={<Instructors />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Fleet" element={<Fleet />} />
          <Route path="/Offer/Category/:category" element={<Category />} />
        </Routes>
      </div>
      <div className='footer'>
        © 2022 <a href='http://techlab24.pl'>Techlab24</a> dla OSK Fenix
      </div>
      <CookieConsent
        buttonText="Akceptuję"
      >
        Informacja o plikach cookies<br /><br />

        W ramach Strony stosujemy pliki cookies. Korzystanie ze Strony bez zmiany ustawień dotyczących cookies oznacza zgodę na ich zapis lub wykorzystanie. Możecie Państwo dokonać zmiany ustawień dotyczących cookies w przeglądarce internetowej w każdym czasie.
      </CookieConsent>
    </div>
  );
}

export default App;
