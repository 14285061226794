import './Prices.css';
import React, { useMemo, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { isMobile } from 'react-device-detect';


function Prices() {
  const [data, setData] = useState({ "prices": [{ title: "" }], "addPrices": [{ title: "" }] });

  useMemo(() => {
    fetch('./data.json'
      , {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Cache-Control': 'no-cache'
        }
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson)
      });
  }, [])

  function drawPrices() {
    if (isMobile) {
      return (
        <table className='table table-striped'>
          <tbody>
            {data["prices"].map(price => {
              if (price.title.includes("Szkolenia Specjalistyczne"))
                return (
                  <tr>
                    <td>
                      {ReactHtmlParser(price.title)}
                    </td>
                  </tr>
                )
              else
                return (
                  <tr>
                    <td>
                      <b>Kurs {ReactHtmlParser(price.title)}</b>
                    </td>
                    <td>
                      Godziny teoretyczne: {price.teoria}
                    </td>
                    <td>
                      Godziny praktyczne:  {price.praktyka}
                    </td>
                    <td>
                      <div className='redPrice'>Cena: {price.cena} zł </div>
                    </td>
                  </tr>
                )
            })}
          </tbody>
        </table>
      )
    }
    else {
      return (
        <table className='table table-striped'>
          <thead class="thead-dark">
            <tr colspan="4">
            </tr>
            <tr>
              <th scope="col"><h3>Kurs</h3></th>
              <th scope="col"><h3>Godziny Teoretyczne</h3></th>
              <th scope="col"><h3>Godziny Praktyczne</h3></th>
              <th scope="col"><h3>Cena</h3></th>
            </tr>
          </thead>
          <tbody>
            {data["prices"].map(price => {
              if (price.title.includes("Szkolenia Specjalistyczne"))
                return (
                  <tr>
                    <td>
                      {ReactHtmlParser(price.title)}
                    </td>
                  </tr>
                )
              else
                return (
                  <tr>
                    <td>
                      {ReactHtmlParser(price.title)}
                    </td>
                    <td>
                      {price.teoria}
                    </td>
                    <td>
                      {price.praktyka}
                    </td>
                    <td>
                      <div className='redPrice'>{price.cena} zł</div>
                    </td>
                  </tr>
                )
            })}
          </tbody>
        </table>
      )
    }
  }

  function drawAddPrices() {
    if (isMobile)
      return (
        <table className='table table-striped table-responsive'>
          <tbody>
            <tr>
              <td>
                <b><h4>Szkolenia dodatkowe (1 godzina)</h4></b>
              </td>
            </tr>
            {data["addPrices"].map(price => {
              return (
                <tr>
                  <td>
                    <b>{ReactHtmlParser(price.title)}</b>
                  </td>
                  <td>
                    Nasz kursant: <div className='redPrice'>{price.nasz} zł</div>
                  </td>
                  <td>
                    Kursant spoza ośrodka: <div className='redPrice'>{price.spoza} zł</div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )
    else
      return (
        <table className='table table-striped table-responsive'>
          <thead class="thead-dark">
            <tr colspan="4">
            </tr>
            <tr>
              <th scope="col"><h4>Szkolenie dodatkowe</h4></th>
              <th scope="col"><h4>Nasz kursant (1 godz.)</h4></th>
              <th scope="col"><h4>Kursant spoza ośrodka (1 godz.)</h4></th>
            </tr>
          </thead>
          <tbody>
            {data["addPrices"].map(price => {
              return (
                <tr>
                  <td>
                    {ReactHtmlParser(price.title)}
                  </td>
                  <td>
                    <div className='redPrice'>{price.nasz} zł</div>
                  </td>
                  <td>
                    <div className='redPrice'>{price.spoza} zł</div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )
  }

  return (
    <div>
      <h1>Cennik</h1>
      {drawPrices()}
      {drawAddPrices()}
      <center><h4>Przy wyborze więcej niż jednej kategorii możliwość negocjacji ceny.</h4></center>
    </div>
  );
}

export default Prices;
